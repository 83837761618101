import './users.css'

import Grid from '@mui/material/Grid'
import { TextField, Button } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { sha256 } from 'js-sha256'
import moment from 'moment'

const theme = createTheme({
  palette: {
    neutral: {
      main: '#4793d7',
      contrastText: '#fff',
    }
  }
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function Users() {

  const { register, getValues } = useForm()
  let navigate = useNavigate()
  let { state } = useLocation()
  const [user, setUser] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(state.company)
  const [users, setUsers] = useState([])
  const [keys, setKeys] = useState([])
  const [accessGroups, setAccessGroups] = useState([])
  const [passwordOpen, setPasswordOpen] = useState(false)
  const [editPassword, setEditPassword] = useState('')
  const [editConfirmPassword, setEditConfirmPassword] = useState('')

  const getCompanyUsers = (currentUser, company) => {
    let usersURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + company + '/users.json?&auth=' + state.idToken
    fetch(usersURL)
    .then(res => res.json())
    .then(json => {
      if (json == null) {
        if (currentUser.hasOwnProperty('adminOf')) {
          // is HR
          let tempGroups = [] // List all Groups user has access to
          currentUser.adminOf.map(group => {
            if (group.brand == company) {
              Object.keys(group.groups).forEach(groupKey => {
                tempGroups.push(group.groups[groupKey])
              })
            }
          })
          setAccessGroups(tempGroups)

          setUsers([])
          setKeys([])

          alert('There are no users!')
        } else {
          // is NOT HR
          let tempGroups = []
          tempGroups.push(currentUser.group)
          navigate('/users2', { state: { idToken: state.idToken, companyId: state.company, adminId: state.username, company: state.company, username: state.username, user: currentUser, accessGroups: tempGroups } })
        }
      } else {
        if (currentUser.hasOwnProperty('adminOf')) {
          // is HR
          let tempArray = []

          let tempGroups = [] // List all Groups user has access to
          currentUser.adminOf.map(group => {
            if (group.brand == company) {
              Object.keys(group.groups).forEach(groupKey => {
                tempGroups.push(group.groups[groupKey])
              })
            }
          })
          setAccessGroups(tempGroups)

          Object.keys(json).forEach(key => { // Put all Groups (Staff Details) that user has access to into an Array
            if (tempGroups.includes(json[key].group)) {
              tempArray.push({ key: key, data: json[key] })
            }
          }) // Convert to array
          setUsers(tempArray)

          let tempKeysArray = []
          Object.keys(json).forEach(key => tempKeysArray.push(key)) // Convert to array
          setKeys(tempKeysArray)
        } else {
          // is NOT HR
          let tempGroups = []
          tempGroups.push(currentUser.group)
          navigate('/users2', { state: { idToken: state.idToken, companyId: state.company, adminId: state.username, company: state.company, username: state.username, user: currentUser, accessGroups: tempGroups } })
        }
      }
    })
  }

  useEffect(() => {
    if (state == null) {
      navigate('/')
    } else {
      let userURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + state.company + '/users/' + state.username + '.json?auth=' + state.idToken
      fetch(userURL)
      .then(res => res.json())
      .then(json => {
        if (json == null) {
          alert('Error! Pulling user profile!')
          navigate('/')
        } else {
          if (json.hasOwnProperty('error')) {
            alert('Session expired!')
            navigate('/')
          } else {
            setUser(json)
            getCompanyUsers(json, state.company)
          }
        }
      })
    }
  }, [])

  const changeCompany = (event) => {
    setSelectedCompany(event.target.value)
    getCompanyUsers(user, event.target.value)
  }

  const openPasswordDialog = () => {
    setEditPassword('')
    setEditConfirmPassword('')
    setPasswordOpen(true)
  }
  const changePassword = () => {
    if (editPassword != editConfirmPassword) {
      alert('Passwords do not match!')
    } else {
      if ((editPassword.trim()).length > 0) {
        var postBody = {}
        postBody.password = sha256(editPassword)
        postBody.lastUpdatedBy = state.username
        postBody.lastUpdated = moment().format()

        let postURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + state.company + '/users/' + state.username + '.json?auth=' + state.idToken
        fetch(postURL, {
          method: 'PATCH',
          body: JSON.stringify(postBody),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(res => res.json())
        .then(json => {
          if (json.hasOwnProperty('error')) {
            alert('Change password failed!')
          } else {
            alert('Password changed successfully!')
            setPasswordOpen(false)
          }
        })
      } else {
        alert('Please enter a valid password!')
      }
    }
  }

  return (
    <div>
      <Grid container spacing={0}>

        {/* Header */}
        <ThemeProvider theme={theme}>
          <AppBar
            position='static'
            color='neutral'
          >
            <Toolbar>
              <Grid
                container
                xs={9}
                sm={9}
                md={9}
                lg={9}
                xl={9}
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
              >
                <Grid item>
                {
                  user && (
                    <img
                      src={user.thumbnail}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        objectFit: 'cover'
                      }}
                    />
                  )
                }
                </Grid>
                <Grid item>
                {
                  user && (
                    <span
                      className='headerText'
                      style={{
                        marginLeft: 20
                      }}
                    >
                      {user.name}
                    </span>
                  )
                }
                </Grid>
              </Grid>
              <Grid
                container
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
                sx={{
                  marginTop: 1
                }}
              >
                <Button
                  size='small'
                  variant='contained'
                  color='neutral'
                  sx={{
                    borderRadius: 2,
                    marginBottom: 1
                  }}
                  onClick={() => {
                    openPasswordDialog()
                  }}
                >
                  Password
                </Button>
                <Button
                  size='small'
                  variant='contained'
                  color='neutral'
                  sx={{
                    borderRadius: 2,
                    marginLeft: 1,
                    marginBottom: 1
                  }}
                  onClick={() => {
                    navigate('/login')
                  }}
                >
                  Logout
                </Button>
              </Grid>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        {/* Header */}

        {
          user && (
            user.hasOwnProperty('adminOf') && (
              user.adminOf.length > 0 && (
                <Grid container>
                  <Grid
                    container
                    xs={0}
                    sm={1}
                    md={2}
                    lg={3}
                    xl={4}
                  />

                  <Grid
                    container
                    xs={12}
                    sm={10}
                    md={8}
                    lg={6}
                    xl={4}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    sx={{
                      marginTop: 5
                    }}
                  >
                    <span
                      style={{
                        marginLeft: 25,
                        marginRight: 25
                      }}
                    >
                      Company
                    </span>

                    <Select
                      id='selectedCompany'
                      value={selectedCompany}
                      onChange={changeCompany}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                    {
                      user.adminOf.map((tag, index) => (
                        <MenuItem
                          key={index}
                          value={tag.brand}
                        >
                          {tag.brand}
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </Grid>

                  <Grid
                    container
                    xs={0}
                    sm={1}
                    md={2}
                    lg={3}
                    xl={4}
                  />
                </Grid>
              )
            )
          )
        }

        {/* Add */}
        <Grid container>
          <Grid
            container
            xs={0}
            sm={1}
            md={2}
            lg={3}
            xl={4}
          />

          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            lg={6}
            xl={4}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              marginTop: 5
            }}
          >
            <ThemeProvider theme={theme}>
              <Button
                size='small'
                variant='contained'
                color='neutral'
                sx={{
                  borderRadius: 2
                }}
                onClick={() => {
                  navigate('/add', { state: { idToken: state.idToken, companyId: state.company, adminId: state.username, company: selectedCompany, users: keys, accessGroups: accessGroups } })
                }}
              >
                Add new user
              </Button>
            </ThemeProvider>
          </Grid>

          <Grid
            container
            xs={0}
            sm={1}
            md={2}
            lg={3}
            xl={4}
          />
        </Grid>
        {/* Add */}

        {/* Users */}
        <Grid
          container
          xs={0}
          sm={1}
          md={2}
          lg={3}
          xl={4}
        />

        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={4}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 0 }} aria-label="simple table">
              {/*
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              */}
              <TableBody>
              {
                users.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={row.data.thumbnail}
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: 35,
                          objectFit: 'cover'
                        }}
                      />
                      <span
                        style={{
                          marginLeft: 20
                        }}
                      >
                        {row.data.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <ThemeProvider theme={theme}>
                        <Button
                          size='small'
                          variant='contained'
                          color='neutral'
                          sx={{
                            borderRadius: 2
                          }}
                          onClick={() => {
                            navigate('/edit', { state: { idToken: state.idToken, companyId: state.company, adminId: state.username, company: selectedCompany, username: row.key, user: row.data, accessGroups: accessGroups } })
                          }}
                        >
                          Edit
                        </Button>
                      </ThemeProvider>
                    </TableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          container
          xs={0}
          sm={1}
          md={2}
          lg={3}
          xl={4}
        />
        {/* Users */}

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop: 10}} />

      </Grid>

      <Dialog
        onClose={() => setPasswordOpen(false)}
        open={passwordOpen}
      >
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 40
          }}
        >
          <DialogTitle
            align={'center'}
          >
            Change Password
          </DialogTitle>

          <TextField
            fullWidth
            autoComplete='new-password'
            label='Enter New Password'
            type='password'
            variant='outlined'
            {...register('password')}
            sx={{
              marginTop: 2
            }}
            value={editPassword}
            onChange={(event) => {
              setEditPassword(event.target.value)
            }}
          />

          <TextField
            fullWidth
            autoComplete='new-password'
            label='Confirm New Password'
            type='password'
            variant='outlined'
            {...register('confirmpassword')}
            sx={{
              marginTop: 2
            }}
            value={editConfirmPassword}
            onChange={(event) => {
              setEditConfirmPassword(event.target.value)
            }}
          />

          <ThemeProvider theme={theme}>
            <Button
              fullWidth
              size='large'
              variant='contained'
              color='neutral'
              sx={{
                borderRadius: 2,
                marginTop: 4
              }}
              onClick={() => {
                const values = getValues()
                changePassword()
                //changePassword(values.password, values.confirmpassword)
              }}
            >
              Submit
            </Button>
          </ThemeProvider>
        </div>
      </Dialog>

    </div>
  )
}
