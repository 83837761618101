import logo from './logo.png'

import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import React from 'react'
import { useNavigate } from 'react-router-dom'

const theme = createTheme({
  palette: {
    neutral: {
      main: '#4793d7',
      contrastText: '#fff',
    }
  }
})

function App() {
  let navigate = useNavigate()

  return (
    <div>
      <Grid container spacing={0}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            marginTop: 2,
            marginBottom: 5
          }}
        >
          <Grid container xs={9} sm={9} md={9} lg={10} xl={11} direction='row' justifyContent='center' alignItems='center' />
          <Grid
            container
            xs={3}
            sm={3}
            md={3}
            lg={2}
            xl={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
          <ThemeProvider theme={theme}>
            <Button
              fullWidth
              size='large'
              variant='contained'
              color='neutral'
              sx={{
                height: 55,
                borderRadius: 2,
                marginRight: 2
              }}
              onClick={() => {
                navigate('/login')
              }}
            >
              Login
            </Button>
          </ThemeProvider>
          </Grid>
        </Grid>


        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            marginTop: 10,
            marginBottom: 5
          }}
        >
          <img
            src={logo}
          />
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            marginTop: 10,
            marginBottom: 5
          }}
        >
          Contact us at <a href={'mailto:wm@seamplify.com'} style={{marginLeft: 5, textDecoration: 'underline', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)'}}>wm@seamplify.com</a>
        </Grid>

      </Grid>
    </div>
  )
}

export default App
