import './edit.css'
import company from './icons/company.png'
import email from './icons/email.png'
import mobile from './icons/mobile.png'
import whatsapp from './icons/whatsapp.png'
import did from './icons/did.png'
import address from './icons/address.png'
import url from './icons/url.png'

import vCardsJS from 'vcards-js'
import QRCode from 'qrcode.react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { TextField, Button, label, input } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { sha256 } from 'js-sha256'
import moment from 'moment'

import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getDatabase, ref, onValue } from 'firebase/database'
import { getStorage, ref as fbsRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import Resizer from 'react-image-file-resizer'

const firebaseConfig = {
  apiKey: 'AIzaSyDKdcYAa-5PYyZMnc3Mtl52n1psxF1PYuw',
  authDomain: 'kardma-me.firebaseapp.com',
  databaseURL: 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app',
  storageBucket: 'kardma-me.appspot.com'
}
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
signInWithEmailAndPassword(auth, 'mings82@gmail.com', 'GCBforMe!')
  //.then((userCredential) => {
  //  const user = userCredential.user
  //})
  //.catch((error) => {
  //  const errorCode = error.code
  //  const errorMessage = error.message
  //})
const database = getDatabase(firebaseApp)
const storage = getStorage(firebaseApp)

const theme = createTheme({
  palette: {
    neutral: {
      main: '#4793d7',
      contrastText: '#fff',
    }
  }
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function Users2() {

  let navigate = useNavigate()
  let { state } = useLocation()

  let cardWidth = 300
  let profileImageHeight = 225

  const { register, getValues } = useForm()
  const [passwordOpen, setPasswordOpen] = useState(false)
  const [editPassword, setEditPassword] = useState('')
  const [editConfirmPassword, setEditConfirmPassword] = useState('')

  // Form Fields
  const [listGroups, setlistGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedGroupKey, setSelectedGroupKey] = useState(null)
  const [selectedGroupLogo, setSelectedGroupLogo] = useState(null)
  const [selectedGroupTagline, setSelectedGroupTagline] = useState(null)
  const [selectedGroupThemeButton, setSelectedGroupThemeButton] = useState(null)
  const [selectedGroupThemeColor, setSelectedGroupThemeColor] = useState(null)

  const [editName, setEditName] = useState(null)
  const [editAltName, setEditAltName] = useState(null)
  const [editTitle, setEditTitle] = useState(null)
  const [editAltTitle, setEditAltTitle] = useState(null)
  const [editCompany, setEditCompany] = useState(null)
  const [editEmail, setEditEmail] = useState(null)
  const [editMobile, setEditMobile] = useState(null)
  const [editDID, setEditDID] = useState(null)
  const [editAddress, setEditAddress] = useState(null)
  const [editURL, setEditURL] = useState(null)
  const [editTemplate, setEditTemplate] = useState(null)
  // Form Fields
  const fileInput = React.useRef()
  const [editImage, setEditImage] = useState(null)
  const [editImageRaw, setEditImageRaw] = useState(null)
  const [editImageThumb, setEditImageThumb] = useState(null)

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // Check for 'image/jpeg' files
      if (event.target.files[0].type == 'image/jpeg') {
        setEditImage(URL.createObjectURL(event.target.files[0]))
        setEditImageRaw(event.target.files[0])

        var reader = new FileReader()
        reader.onload = function (e) {
          var img = new Image;
          img.onload = function() {
            resizeImage(event.target.files[0], img.width, img.height)
          }
          img.src = reader.result
        }
        reader.readAsDataURL(event.target.files[0])
      } else {
        alert('Invalid file type!')
      }
    }
  }

  const resizeImage = (file, width, height) => {
    var newWidth = 256
    var newHeight = 256 * height / width

    try {
      Resizer.imageFileResizer(
        file,
        newWidth,
        newHeight,
        'JPEG',
        100,
        0,
        (uri) => {
          setEditImageThumb(uri)
        },
        'file',
        newWidth,
        newHeight
      )
    } catch (err) {
      alert(err)
    }
  }

  const getVsCard = () => {
    var vCard = vCardsJS()

    let vCardMobile = editMobile != null ? editMobile.replace(/ /g, '') : ''
    let vCardDid = editDID != null ? editDID.replace(/ /g, '') : ''

    vCard.firstName = editName
    vCard.organization = editCompany
    vCard.role = editTitle
    vCard.workEmail = editEmail
    vCard.cellPhone = vCardMobile
    vCard.workPhone = vCardDid
    vCard.workAddress.street = editAddress
    vCard.workUrl = editURL

    return vCard.getFormattedString()
  }

  useEffect(() => {
    if (state == null) {
      navigate(-1)
    } else {
      const databaseRef = ref(database, state.company + '/groups')
      onValue(databaseRef, (snapshot) => {
        const data = snapshot.val()

        let tempArray = []
        Object.keys(data).forEach((key, index) => {
          //tempArray.push({ key: key, data: data[key] })
          if ((state.accessGroups).includes(key)) {
            tempArray.push({ key: key, data: data[key] })

            if (key == state.user.group) {
              setSelectedGroup(tempArray.length - 1)
              //setSelectedGroup(index)
              setSelectedGroupKey(key)
              setSelectedGroupLogo({
                logo: data[key].logo,
                logoH: data[key].logoH,
                logoW: data[key].logoW
              })
              setSelectedGroupTagline(data[key].tagline)
              setSelectedGroupThemeButton(data[key].themeButton)
              setSelectedGroupThemeColor(data[key].themeColor)
            }
          }
        }) // Set selectedGroup // Convert to array
        setlistGroups(tempArray)
      })

      setEditImage(state.user.image)
      setEditName(state.user.name)
      setEditAltName(state.user.altName)
      setEditTitle(state.user.title)
      setEditAltTitle(state.user.altTitle)
      setEditCompany(state.user.company)
      setEditEmail(state.user.email)
      setEditMobile(state.user.mobile)
      setEditDID(state.user.did)
      setEditAddress(state.user.address)
      setEditURL(state.user.url)
      setEditTemplate(state.user.template)
    }
  }, [])

  const editUser = (values) => {

    var postBody = {}

    postBody.lastUpdatedBy = state.companyId + ' - ' + state.adminId
    postBody.lastUpdated = moment().format()

    if (values.name != '') { // Changes made
      if ((values.name).trim() == '') {
        postBody.name = null
      } else {
        postBody.name = (values.name).trim()
      }
    } else { // Deleting entry
      if (state.user.name != editName) {
        postBody.name = null
      }
    }

    if (values.altName != '') { // Changes made
      if ((values.altName).trim() == '') {
        postBody.altName = null
      } else {
        postBody.altName = (values.altName).trim()
      }
    } else { // Deleting entry
      if (state.user.altName != editAltName) {
        postBody.altName = null
      }
    }

    if (values.title != '') { // Changes made
      if ((values.title).trim() == '') {
        postBody.title = null
      } else {
        postBody.title = (values.title).trim()
      }
    } else { // Deleting entry
      if (state.user.title != editTitle) {
        postBody.title = null
      }
    }

    if (values.altTitle != '') { // Changes made
      if ((values.altTitle).trim() == '') {
        postBody.altTitle = null
      } else {
        postBody.altTitle = (values.altTitle).trim()
      }
    } else { // Deleting entry
      if (state.user.altTitle != editAltTitle) {
        postBody.altTitle = null
      }
    }

    if (values.company != '') { // Changes made
      if ((values.company).trim() == '') {
        postBody.company = null
      } else {
        postBody.company = (values.company).trim()
      }
    } else { // Deleting entry
      if (state.user.company != editCompany) {
        postBody.company = null
      }
    }

    if (values.email != '') { // Changes made
      if ((values.email).trim() == '') {
        postBody.email = null
      } else {
        postBody.email = (values.email).trim()
      }
    } else { // Deleting entry
      if (state.user.email != editEmail) {
        postBody.email = null
      }
    }

    if (values.mobile != '') { // Changes made
      if ((values.mobile).trim() == '') {
        postBody.mobile = null
      } else {
        postBody.mobile = (values.mobile).trim()
      }
    } else { // Deleting entry
      if (state.user.mobile != editMobile) {
        postBody.mobile = null
      }
    }

    if (values.did != '') { // Changes made
      if ((values.did).trim() == '') {
        postBody.did = null
      } else {
        postBody.did = (values.did).trim()
      }
    } else { // Deleting entry
      if (state.user.did != editDID) {
        postBody.did = null
      }
    }

    if (values.address != '') { // Changes made
      if ((values.address).trim() == '') {
        postBody.address = null
      } else {
        postBody.address = (values.address).trim()
      }
    } else { // Deleting entry
      if (state.user.address != editAddress) {
        postBody.address = null
      }
    }

    if (values.url != '') { // Changes made
      if ((values.url).trim() == '') {
        postBody.url = null
      } else {
        postBody.url = (values.url).trim()
      }
    } else { // Deleting entry
      if (state.user.url != editURL) {
        postBody.url = null
      }
    }

    if (state.user.template != editTemplate) {
      postBody.template = editTemplate
    }

    if (state.user.group != selectedGroupKey) {
      postBody.group = selectedGroupKey
      postBody.logo = selectedGroupLogo.logo
      postBody.logoH = selectedGroupLogo.logoH
      postBody.logoW = selectedGroupLogo.logoW
      postBody.tagline = selectedGroupTagline
      postBody.themeButton = selectedGroupThemeButton
      postBody.themeColor = selectedGroupThemeColor
    }

    if (editImageRaw == null) {
      let postURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + state.company + '/users/' + state.username + '.json?auth=' + state.idToken
      fetch(postURL, {
        method: 'PATCH',
        body: JSON.stringify(postBody),
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res.json())
      .then(json => {
        if (json.hasOwnProperty('error')) {
          alert('Edit user failed!')
        } else {
          alert('User edited successfully!')
          navigate(-1)
        }
      })
    } else {
      // Add Image / Photo
      const metadata = {
        contentType: 'image/jpeg'
      }
      const storageRef = fbsRef(storage, state.company + '/' + state.username + '.jpg')
      const uploadTask = uploadBytesResumable(storageRef, editImageRaw, metadata)

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload [Image] is ' + progress + '% done')
          //switch (snapshot.state) {
          //  case 'paused':
          //    console.log('Upload is paused')
          //    break
          //  case 'running':
          //    console.log('Upload is running')
          //    break
          //}
        },
        (error) => {
          alert(error.code)
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          //switch (error.code) {
          //  case 'storage/unauthorized':
          //    // User doesn't have permission to access the object
          //    break
          //  case 'storage/canceled':
          //    // User canceled the upload
          //    break
          //  // ...
          //  case 'storage/unknown':
          //    // Unknown error occurred, inspect error.serverResponse
          //    break
          //}
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File [Image] available at', downloadURL)
            postBody.image = downloadURL

            // Add Thumbnail
            const storageRef2 = fbsRef(storage, state.company + '/' + state.username + '_thumb.jpg')
            const uploadTask2 = uploadBytesResumable(storageRef2, editImageThumb, metadata)
            uploadTask2.on('state_changed',
              (snapshot2) => {
                const progress2 = (snapshot2.bytesTransferred / snapshot2.totalBytes) * 100
                console.log('Upload [Thumbnail] is ' + progress2 + '% done')
              },
              (error2) => {
                alert(error2.code)
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask2.snapshot.ref).then((downloadURL2) => {
                  console.log('File [Thumbnail] available at', downloadURL2)
                  postBody.thumbnail = downloadURL2

                  // Add Other Details
                  let postURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + state.company + '/users/' + state.username + '.json?auth=' + state.idToken
                  fetch(postURL, {
                    method: 'PATCH',
                    body: JSON.stringify(postBody),
                    headers: { 'Content-Type': 'application/json' },
                  })
                  .then(res => res.json())
                  .then(json => {
                    if (json.hasOwnProperty('error')) {
                      alert('Edit user failed!')
                    } else {
                      alert('User edited successfully!')
                      navigate(-1)
                    }
                  })
                })
              }
            )
          })
        }
      )
    }
  }

  const openPasswordDialog = () => {
    setEditPassword('')
    setEditConfirmPassword('')
    setPasswordOpen(true)
  }
  const changePassword = () => {
    if (editPassword != editConfirmPassword) {
      alert('Passwords do not match!')
    } else {
      if ((editPassword.trim()).length > 0) {
        var postBody = {}
        postBody.password = sha256(editPassword)
        postBody.lastUpdatedBy = state.username
        postBody.lastUpdated = moment().format()

        let postURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + state.company + '/users/' + state.username + '.json?auth=' + state.idToken
        fetch(postURL, {
          method: 'PATCH',
          body: JSON.stringify(postBody),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(res => res.json())
        .then(json => {
          if (json.hasOwnProperty('error')) {
            alert('Change password failed!')
          } else {
            alert('Password changed successfully!')
            setPasswordOpen(false)
          }
        })
      } else {
        alert('Please enter a valid password!')
      }
    }
  }

  return (
    <div>

    {/* Header */}
    <ThemeProvider theme={theme}>
      <AppBar
        position='static'
        color='neutral'
      >
        <Toolbar>
          <Grid
            container
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <Grid item>
            {
              state.user && (
                <img
                  src={state.user.thumbnail}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    objectFit: 'cover'
                  }}
                />
              )
            }
            </Grid>
            <Grid item>
            {
              state.user && (
                <span
                  className='headerText'
                  style={{
                    marginLeft: 20
                  }}
                >
                  {state.user.name}
                </span>
              )
            }
            </Grid>
          </Grid>
          <Grid
            container
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              marginTop: 1
            }}
          >
            <Button
              size='small'
              variant='contained'
              color='neutral'
              sx={{
                borderRadius: 2,
                marginBottom: 1
              }}
              onClick={() => {
                openPasswordDialog()
              }}
            >
              Password
            </Button>
            <Button
              size='small'
              variant='contained'
              color='neutral'
              sx={{
                borderRadius: 2,
                marginLeft: 1,
                marginBottom: 1
              }}
              onClick={() => {
                navigate('/login')
              }}
            >
              Logout
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
    {/* Header */}

    <Grid container sx={{ marginTop: 5 }}>
      <Grid
        container
        xs={0}
        sm={1}
        md={2}
        lg={3}
        xl={4}
      />

      {/* Form */}
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={4}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Group
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <Select
            fullWidth
            id='selectedGroup'
            value={selectedGroup}
            onChange={(event) => {
              setSelectedGroup(event.target.value)
              setSelectedGroupKey(listGroups[event.target.value].key)
              setSelectedGroupLogo({
                logo: listGroups[event.target.value].data.logo,
                logoH: listGroups[event.target.value].data.logoH,
                logoW: listGroups[event.target.value].data.logoW
              })
              setSelectedGroupTagline(listGroups[event.target.value].data.tagline)
              setSelectedGroupThemeButton(listGroups[event.target.value].data.themeButton)
              setSelectedGroupThemeColor(listGroups[event.target.value].data.themeColor)
            }}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
          {
            listGroups.map((group, index) => (
              <MenuItem
                key={group.key}
                value={index}
              >
                {group.data.label}
              </MenuItem>
            ))
          }
          </Select>
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Template
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <Select
            fullWidth
            id='editTemplate'
            value={editTemplate}
            onChange={(event) => {
              setEditTemplate(event.target.value)
            }}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            <MenuItem key={1} value={1}>
              1
            </MenuItem>
            <MenuItem key={2} value={2}>
              2
            </MenuItem>
          </Select>
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 2}} />
        <Grid container xs={4} sm={4} md={4} lg={4} xl={4} direction='row' justifyContent='center' sx={{marginTop: 2}}>
          Sample Template 1
        </Grid>
        <Grid container xs={4} sm={4} md={4} lg={4} xl={4} direction='row' justifyContent='center' sx={{marginTop: 2}}>
          Sample Template 2
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}} />
        <Grid container xs={4} sm={4} md={4} lg={4} xl={4} direction='row' justifyContent='center' sx={{marginTop: 1}}>
          <img
            src={editImage}
            style={{
              width: 120,
              height: 90,
              borderRadius: 5,
              objectFit: 'cover'
            }}
          />
        </Grid>
        <Grid container xs={4} sm={4} md={4} lg={4} xl={4} direction='row' justifyContent='center' sx={{marginTop: 1}}>
          <img
            src={editImage}
            style={{
              width: 95,
              height: 95,
              borderRadius: 95,
              objectFit: 'cover'
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 3}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 3}} />
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} direction='row' justifyContent='flex-end' sx={{marginTop: 3}}>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>fileInput.current.click()}
          >
            Edit Photo
          </Button>

          <input
            ref={fileInput}
            type='file'
            style={{ display: 'none' }}
            onChange={onImageChange}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 3}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 2}}>
          Name
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 2}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('name')}
            value={editName}
            onChange={(event) => {
              setEditName(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Alt Name
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('altName')}
            value={editAltName}
            onChange={(event) => {
              setEditAltName(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Title
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('title')}
            value={editTitle}
            onChange={(event) => {
              setEditTitle(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Alt Title
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('altTitle')}
            value={editAltTitle}
            onChange={(event) => {
              setEditAltTitle(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Logo
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} direction='row' alignItems='center' sx={{marginTop: 1, height: 60}}>
        {
          selectedGroupLogo && (
            <img
              src={selectedGroupLogo.logo}
              style={{
                width: selectedGroupLogo.logoW / selectedGroupLogo.logoH * 40,
                height: 40,
                borderRadius: 5,
                objectFit: 'cover'
              }}
            />
          )
        }
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Company
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('company')}
            value={editCompany}
            onChange={(event) => {
              setEditCompany(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Email
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('email')}
            value={editEmail}
            onChange={(event) => {
              setEditEmail(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Mobile
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('mobile')}
            value={editMobile}
            onChange={(event) => {
              setEditMobile(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          DID
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('did')}
            value={editDID}
            onChange={(event) => {
              setEditDID(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          Address
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            variant='outlined'
            autoComplete='new-password'
            {...register('address')}
            value={editAddress}
            onChange={(event) => {
              setEditAddress(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 1}}>
          URL
        </Grid>
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} sx={{marginTop: 1}}>
          <TextField
            fullWidth
            autoComplete='new-password'
            variant='outlined'
            {...register('url')}
            value={editURL}
            onChange={(event) => {
              setEditURL(event.target.value)
            }}
          />
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 1}} />

        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />
        <Grid container xs={2} sm={2} md={2} lg={2} xl={2} sx={{marginTop: 2}} />
        <Grid container xs={8} sm={8} md={8} lg={8} xl={8} direction='row' justifyContent='flex-end' alignItems='center' sx={{marginTop: 2}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              const values = getValues()
              editUser(values)
            }}
          >
            Save Changes
          </Button>
        </Grid>
        <Grid container xs={1} sm={1} md={1} lg={1} xl={1} sx={{marginTop: 2}} />

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop: 10}} />

      </Grid>
      {/* Form */}

      <Grid
        container
        xs={0}
        sm={1}
        md={2}
        lg={3}
        xl={4}
      >
        <Box
          component={Grid}
          item
          lg={12}
          xl={12}
          display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}
        >
          <span
            style={{
              width: cardWidth,
            }}
          >
            Preview
          </span>

          <div
            style={{
              width: cardWidth,
              marginTop: 15
            }}
          >
          {
            editTemplate == 1 ? (
              // Template 1
              <div>
                {/* Gradient and Tagline */}
                {
                  selectedGroupTagline == true && (
                    <div
                      className='Gradient'
                      style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        height: 40,
                        paddingTop: 10,
                        paddingRight: 10,
                        elevation: 999
                      }}
                    >
                      <span className='Tag1'>
                        CULTIVATORS
                      </span>
                      <span className='Tag2'>
                        of
                      </span>
                      <span className='Tag1'>
                        CHANGE
                      </span>
                    </div>
                  )
                }
                {/* Gradient and Tagline */}

                {/* Profile Photo*/}
                <div
                  style={{
                    marginTop: selectedGroupTagline == true ? -50 : 0
                  }}
                >
                  <img
                    src={editImage}
                    style={{
                      width: cardWidth,
                      height: profileImageHeight,
                      objectFit: 'cover'
                    }}
                  />
                </div>
                {/* Profile Photo */}

                <div
                  className='DesignLine'
                  style={{
                    position: 'relative',
                    backgroundColor: selectedGroupThemeColor,
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    marginTop: -35,
                    height: 40
                  }}
                />
                <div
                  className='DesignLine2'
                  style={{
                    position: 'relative',
                    backgroundColor: 'rgba(255, 255, 255, 1.0)',
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    marginTop: -33,
                    height: 45,
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                />

                <div
                  style={{
                    position: 'relative',
                    width: cardWidth,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: -40,
                    zIndex: 105
                  }}
                >
                {
                  selectedGroupLogo && (
                    <img
                      src={selectedGroupLogo.logo}
                      width={selectedGroupLogo.logoW/selectedGroupLogo.logoH * 30}
                      height={30}
                      layout={'intrinsic'}
                    />
                  )
                }
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 10
                  }}
                >
                  <QRCode
                    value={'https://' + state.company + '.kardma.me/' + state.username}
                    className='QRCode'
                    style={{
                      position: 'relative',
                      width: 0.20 * cardWidth,
                      height: 0.20 * cardWidth,
                      marginRight: 20,
                      elevation: 998,
                      zIndex: 104
                    }}
                    onClick={() => {
                      var reader = new FileReader()
                      const blob = new Blob([ getVsCard() ], {type: 'text/vcard;charset=utf-8'})

                      reader.onload = function(e) {
                        window.location.href = reader.result
                      }
                      reader.readAsDataURL(blob)
                    }}
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    zIndex: 102,
                    marginTop: -0.20 * cardWidth
                  }}
                >
                  {/* Left Column */}
                  <div
                    style={{
                      width: 0.8 * cardWidth - 20,
                      paddingLeft: 20
                    }}
                  >
                    <div className='TextContainer' style={{marginTop: 0}}>
                      <span className='Header1'>
                        {editName}
                      </span>
                    </div>

                    {
                      editAltName != 'null' && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header1'>
                            {editAltName}
                          </span>
                        </div>
                      )
                    }

                    {
                      editTitle != null && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header2'>
                            {editTitle}
                          </span>
                        </div>
                      )
                    }

                    {
                      editAltTitle != null && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header2'>
                            {editAltTitle}
                          </span>
                        </div>
                      )
                    }
                  </div>
                  {/* Right Column */}
                  <div
                    style={{
                      width: 0.20 * cardWidth + 20
                    }}
                  >
                    <div
                      className='Download'
                      style={{
                        width: 0.20 * cardWidth + 20,
                        marginTop: 0.20 * cardWidth + 5,
                        marginRight: 20,
                        elevation: 999
                      }}
                      onClick={() => {
                        var reader = new FileReader()
                        const blob = new Blob([ getVsCard() ], {type: 'text/vcard;charset=utf-8'})

                        reader.onload = function(e) {
                          window.location.href = reader.result
                        }
                        reader.readAsDataURL(blob)
                      }}
                    >
                      Save Contact
                    </div>
                  </div>
                </div>
              </div>
              // Template 1
            ) : (
              // Template 2
              <div>
                {/* Profile Photo */}
                <div
                  style={{
                    width: cardWidth,
                    height: profileImageHeight - 80,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      width: profileImageHeight * 0.5,
                      height: profileImageHeight * 0.5,
                      borderRadius: profileImageHeight * 0.5,
                      position: 'relative',
                      overflow: 'hidden',
                      marginLeft: 15
                    }}
                  >
                    <img
                      src={editImage}
                      width={profileImageHeight * 0.5}
                      height={profileImageHeight * 0.5}
                      style={{
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: cardWidth - (profileImageHeight * 0.5) - 15,
                      height: profileImageHeight * 0.5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                  {
                    selectedGroupLogo && (
                      <div
                        style={{
                          width: selectedGroupLogo.logoW/selectedGroupLogo.logoH * (profileImageHeight * 0.17),
                          height: profileImageHeight * 0.17,
                          position: 'relative',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          src={selectedGroupLogo.logo}
                          width={selectedGroupLogo.logoW/selectedGroupLogo.logoH * (profileImageHeight * 0.17)}
                          height={profileImageHeight * 0.17}
                          objectFit={'cover'}
                        />
                      </div>
                    )
                  }
                  </div>
                </div>
                {/* Profile Photo */}

                {/* Tagline */}
                {
                  selectedGroupTagline == true ? (
                    <div
                      className='GradientA'
                      style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'flex-end',
                        height: 80,
                        paddingTop: 10,
                        paddingRight: 15,
                        elevation: 999,
                        marginTop: -25
                      }}
                    >
                      <span className='Tag1a'>
                        CULTIVATORS
                      </span>
                      <span className='Tag2a'>
                        of
                      </span>
                      <span className='Tag1a'>
                        CHANGE
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 80,
                        marginTop: -25
                      }}
                    />
                  )
                }
                {/* Tagline */}

                <div
                  className='DesignLine'
                  style={{
                    position: 'relative',
                    backgroundColor: selectedGroupThemeColor,
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    marginTop: -55,
                    height: 40
                  }}
                />
                <div
                  className='DesignLine2'
                  style={{
                    position: 'relative',
                    backgroundColor: 'rgba(255, 255, 255, 1.0)',
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                    marginTop: -33,
                    height: 45,
                    paddingTop: 10,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: -35
                  }}
                >
                  <QRCode
                    value={'https://' + state.company + '.kardma.me/' + state.username}
                    className='QRCode'
                    style={{
                      position: 'relative',
                      width: 0.20 * cardWidth,
                      height: 0.20 * cardWidth,
                      marginRight: 20,
                      elevation: 998,
                      zIndex: 104
                    }}
                    onClick={() => {
                      var reader = new FileReader()
                      const blob = new Blob([ getVsCard() ], {type: 'text/vcard;charset=utf-8'})

                      reader.onload = function(e) {
                        window.location.href = reader.result
                      }
                      reader.readAsDataURL(blob)
                    }}
                  />
                </div>

                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    zIndex: 102,
                    marginTop: -0.20 * cardWidth
                  }}
                >
                  {/* Left Column */}
                  <div
                    style={{
                      width: 0.8 * cardWidth - 20,
                      paddingLeft: 20
                    }}
                  >
                    <div className='TextContainer' style={{marginTop: 0}}>
                      <span className='Header1'>
                        {editName}
                      </span>
                    </div>

                    {
                      editAltName != null && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header1'>
                            {editAltName}
                          </span>
                        </div>
                      )
                    }

                    {
                      editTitle != null && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header2'>
                            {editTitle}
                          </span>
                        </div>
                      )
                    }

                    {
                      editAltTitle != null && (
                        <div className='TextContainer' style={{marginTop: 0}}>
                          <span className='Header2'>
                            {editAltTitle}
                          </span>
                        </div>
                      )
                    }
                  </div>
                  {/* Right Column */}
                  <div
                    style={{
                      width: 0.20 * cardWidth + 20
                    }}
                  >
                    <div
                      className='Download'
                      style={{
                        width: 0.20 * cardWidth + 20,
                        marginTop: 0.20 * cardWidth + 5,
                        marginRight: 20,
                        elevation: 999
                      }}
                      onClick={() => {
                        var reader = new FileReader()
                        const blob = new Blob([ getVsCard() ], {type: 'text/vcard;charset=utf-8'})

                        reader.onload = function(e) {
                          window.location.href = reader.result
                        }
                        reader.readAsDataURL(blob)
                      }}
                    >
                      Save Contact
                    </div>
                  </div>
                </div>
              </div>
              // Template 2
            )
          }

          {/* Details Sheet */}
          {
            editCompany != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={company}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={editURL} target='_blank' style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editCompany}
                    </a>
                  ) : (
                    <a href={editURL} target='_blank'>
                      {editCompany}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }

          {
            editEmail != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={email}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={'mailto:' + editEmail} style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editEmail}
                    </a>
                  ) : (
                    <a href={'mailto:' + editEmail}>
                      {editEmail}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }

          {
            editMobile != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={mobile}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={'tel:' + editMobile.replace(/ /g, '')} style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editMobile}
                    </a>
                  ) : (
                    <a href={'tel:' + editMobile.replace(/ /g, '')}>
                      {editMobile}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }

          {
            editMobile != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={whatsapp}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={'https://wa.me/' + editMobile.replace('+', '').replace(/ /g, '')} style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editMobile}
                    </a>
                  ) : (
                    <a href={'https://wa.me/' + editMobile.replace('+', '').replace(/ /g, '')}>
                      {editMobile}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }

          {
            editDID != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={did}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={'tel:' + editDID.replace(/ /g, '')} style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editDID}
                    </a>
                  ) : (
                    <a href={'tel:' + editDID.replace(/ /g, '')}>
                      {editDID}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }

          {
            editAddress != null && (
              <div className='DetailsContainer' style={{marginTop: 8}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={address}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                {
                  selectedGroupThemeButton != null ? (
                    <span className='Details' style={{width: cardWidth - 75, backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      <a href={'https://www.google.com/maps/search/?api=1&query=' + editAddress}>
                        {editAddress}
                      </a>
                    </span>
                  ) : (
                    <span className='Details' style={{width: cardWidth - 75}}>
                      <a href={'https://www.google.com/maps/search/?api=1&query=' + editAddress}>
                        {editAddress}
                      </a>
                    </span>
                  )
                }
              </div>
            )
          }

          {
            editURL != null && (
              <div className='DetailsContainer' style={{marginTop: 8, marginBottom: 40}}>
                <div className='IconsContainer' style={{backgroundColor: selectedGroupThemeColor, marginLeft: 15, paddingTop: 0, paddingLeft: 0}}>
                  <img
                    src={url}
                    width={16}
                    height={16}
                    style={{
                      marginTop: 5,
                      marginLeft: 5
                    }}
                  />
                </div>
                <span className='Details' style={{width: cardWidth - 75}}>
                {
                  selectedGroupThemeButton != null ? (
                    <a href={editURL} target='_blank' style={{backgroundColor: selectedGroupThemeButton, paddingTop: 6, paddingBottom: 6, paddingLeft: 10, paddingRight: 10, borderRadius: 20}}>
                      {editURL}
                    </a>
                  ) : (
                    <a href={editURL} target='_blank'>
                      {editURL}
                    </a>
                  )
                }
                </span>
              </div>
            )
          }
          {/* Details Sheet */}
          </div>
        </Box>
      </Grid>

    </Grid>

    <Dialog
      onClose={() => setPasswordOpen(false)}
      open={passwordOpen}
    >
      <div
        style={{
          paddingTop: 10,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40
        }}
      >
        <DialogTitle
          align={'center'}
        >
          Change Password
        </DialogTitle>

        <TextField
          fullWidth
          autoComplete='new-password'
          label='Enter New Password'
          type='password'
          variant='outlined'
          {...register('password')}
          sx={{
            marginTop: 2
          }}
          value={editPassword}
          onChange={(event) => {
            setEditPassword(event.target.value)
          }}
        />

        <TextField
          fullWidth
          autoComplete='new-password'
          label='Confirm New Password'
          type='password'
          variant='outlined'
          {...register('confirmpassword')}
          sx={{
            marginTop: 2
          }}
          value={editConfirmPassword}
          onChange={(event) => {
            setEditConfirmPassword(event.target.value)
          }}
        />

        <ThemeProvider theme={theme}>
          <Button
            fullWidth
            size='large'
            variant='contained'
            color='neutral'
            sx={{
              borderRadius: 2,
              marginTop: 4
            }}
            onClick={() => {
              const values = getValues()
              changePassword()
              //changePassword(values.password, values.confirmpassword)
            }}
          >
            Submit
          </Button>
        </ThemeProvider>
      </div>
    </Dialog>

    </div>
  )
}
