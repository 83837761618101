import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Login from './routes/login';
import Users from './routes/users';
import Users2 from './routes/users2';
import Add from './routes/add';
import Edit from './routes/edit';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='login' element={<Login />} />
      <Route path='users' element={<Users />} />
      <Route path='users2' element={<Users2 />} />
      <Route path='add' element={<Add />} />
      <Route path='edit' element={<Edit />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
