import logo from '../logo.png'
import '../App.css'

import Grid from '@mui/material/Grid'
import { TextField, Button } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { sha256 } from 'js-sha256'

const theme = createTheme({
  palette: {
    neutral: {
      main: '#4793d7',
      contrastText: '#fff',
    }
  }
})

function Login() {
  const { register, getValues } = useForm()
  let navigate = useNavigate()

  const auth = (company, username, password) => {

    let authURL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyDKdcYAa-5PYyZMnc3Mtl52n1psxF1PYuw'
    let authBody = JSON.stringify({
      'email': 'mings82@gmail.com',
      'password': 'GCBforMe!',
      'returnSecureToken': true
    })
    fetch(authURL, {
      method: 'POST',
      body: authBody,
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(json => {
      if (json.idToken) {
        // Firebase authentication successful

        let userURL = 'https://kardma-me-default-rtdb.asia-southeast1.firebasedatabase.app/' + company + '/users/' + username + '.json?auth=' + json.idToken
        fetch(userURL)
        .then(res2 => res2.json())
        .then(json2 => {
          if (json2 == null) {
            alert('Oops! Your account does not exist!')
          } else {
            if (json2.hasOwnProperty('password')) {
              if (sha256(password) == json2.password) {
                navigate('/users', { state: { idToken: json.idToken, company: company, username: username } })
              } else {
                alert('Oops! Your password is invalid!')
              }
            } else {
              alert('Oops! You need to be an admin to login!')
            }
          }
        })
      } else {
        alert('Error! Please contact system administrator!')
      }
    })
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            marginTop: 10,
            marginBottom: 5
          }}
        >
          <img
            src={logo}
          />
        </Grid>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Grid
            container
            xs={1}
            sm={2}
            md={2}
            lg={4}
            xl={5}
            direction='row'
            justifyContent='center'
            alignItems='center'
          />

          <Grid
            container
            xs={10}
            sm={8}
            md={8}
            lg={4}
            xl={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div className='loginFields'>
              <TextField
                fullWidth
                autoComplete='new-password'
                label='Company'
                variant='outlined'
                {...register('company')}
              />
            </div>
            <div className='loginFields'>
              <TextField
                fullWidth
                autoComplete='new-password'
                label='Username'
                variant='outlined'
                {...register('username')}
              />
            </div>
            <div className='loginFields'>
              <TextField
                fullWidth
                autoComplete='new-password'
                label='Password'
                type='password'
                variant='outlined'
                {...register('password')}
              />
            </div>

            <div className='loginButton'>
              <ThemeProvider theme={theme}>
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  color='neutral'
                  sx={{
                    height: 55,
                    borderRadius: 2
                  }}
                  onClick={() => {
                    const values = getValues()
                    auth(values.company, values.username, values.password)
                  }}
                >
                  Login
                </Button>
              </ThemeProvider>
            </div>
          </Grid>

          <Grid
            container
            xs={1}
            sm={2}
            md={2}
            lg={4}
            xl={5}
            direction='row'
            justifyContent='center'
            alignItems='center'
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
